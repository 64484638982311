import {
  AudioBar,
  ButtonNavigation,
  SearchModal,
  MemberGroupModal,
} from '../components'

import {
  by,
  generateGroupList,
  getRandomItems,
} from '../../lib'

import { icons, project } from '../../store'

const { defaultPhotos } = project.paths

class Testimonials {
  constructor(state, props) {
    const [testimonials] = props
    this.donors = state.donors || []
    this.testimonials = testimonials.sort(by('name', 'index'))
  }

  renderFullTestimonial() {
    const { testimonials } = this

    const featurableTestimonials = testimonials
      .filter(donor => !defaultPhotos.includes(donor.photo))

    const donor = featurableTestimonials.length
      ? getRandomItems(featurableTestimonials, 1)
      : null

    if (!donor || !donor.name) return ''

    const { name, photo, index } = donor

    return `
    <div class="flex-row">
      <figure class="featured-figure">
        <img class="featured-testimonial_image" src="${photo}">
        ${AudioBar(donor).render()}
      </figure>
      <aside class="featured-testimonial_details">
        <h3>
          <a href="/donor/${index}" data-navigo>
            ${name}
          </a>
        </h3>
        <ul>
          ${generateGroupList(donor)}
        </ul>
        <button class="random-testimonial">
          Random
          ${icons['refresh-mini']()}
        </button>
      </aside>
    </div>
    `
  }

  renderTestimonialList() {
    const { testimonials } = this
    return testimonials.reduce((HTML, donor) => HTML + `
      <dt class="testimonial_title">
        <a href="/donor/${donor.index}" data-navigo>
          ${donor.name}
        </a>
      </dt>
      <dd class="testimonial">
        <img class="testimonial_image" src="${donor.thumbnail}">
        ${AudioBar(donor, { completion: 0 }).render()}
      </dd>
    `, '')
  }

  render() {
    return `
    ${SearchModal().render()}
    ${MemberGroupModal().render()}
    <div id="testimonials" class="basic-page">
      <main>
        <section class="testimonials-header">
          <h1>Testimonials</h1>
          <p>Listen to what donors have to say about their support of St. Louis Public Radio</p>
        </section>

        <section class="featured-testimonial">
          ${this.renderFullTestimonial()}
        </section>

        <section class="all-testimonials">
          <h3>All Testimonials</h3>
          <dl class="testimonials-list">
            ${this.renderTestimonialList()}
          </dl>
        </section>
      </main>

      <footer>
        ${ButtonNavigation().render()}
      </footer>
    </div>
    `
  }
}

export default Testimonials
