const project = {
  paths: {
    defaultPhotos: [
      'https://npr.brightspotcdn.com/d3/ab/8849cff04f2db7e40a28515e59c2/donors-1.jpg',
      'https://npr.brightspotcdn.com/e5/5d/41ce05e844548cab6fc8c7de59db/donors-2.jpg',
      'https://npr.brightspotcdn.com/d8/51/5a5c90994afa810e97f8e10e42c9/donors-3.jpg',
      'https://npr.brightspotcdn.com/7a/04/d331b19046c2993b87cf53cb062e/donors-4.jpg',
      'https://npr.brightspotcdn.com/74/c3/6d377da54406b8cae7f4331747ae/donors-5.jpg',
    ],
    defaultThumbnails: [
      'https://npr.brightspotcdn.com/84/e9/7fa8e2d6442a96c7a061b5b0a60f/thumbnail-1.svg',
      'https://npr.brightspotcdn.com/a2/9c/f8b644a74de7806f3d96a8ac4ccf/thumbnail-2.svg',
    ],
    alias: 'https://stlpr-donor-board.netlify.com',
    canonical: 'https://donor-board.stlpr.org',
  },

  manualPhotos: {
    //  Name in Donor Record                     // [Rectangle 3x4 or 4x3 photo, Square thumbnail]
    'Steven Novik & Cathy Barancik': [
      'https://npr.brightspotcdn.com/e8/09/92ab3ed34c6eb9c709fb02fcce7d/barancik-cathy-novik-steve-ttt2010-rect.jpg',
      'https://npr.brightspotcdn.com/08/91/5f0dce1a4f8ba0b771f4928169af/barancik-cathy-novik-steve-ttt2010-square.jpg',
    ],
    'Drs. Nanci & James Bobrow': [
      'https://npr.brightspotcdn.com/da/ba/f92a054744619e40b85d2b2ed335/bobrow-jim-nancy-2010-rect.jpg',
      'https://npr.brightspotcdn.com/3e/02/e6ac9d624f608083f3c2b6b90169/bobrow-jim-nancy-2010-square.jpg',
    ],
    'Jerry & Rosalie Brasch': [
      'https://npr.brightspotcdn.com/dc/e6/80e5e14e4318a40723c2c8be4e14/brasch-ttt2014-rect.jpg',
      'https://npr.brightspotcdn.com/40/c6/1fd56b3d48848bf92ba691b77209/brasch-ttt2014-square.jpg',
    ],
    'Charles & Bunny Burson': [
      'https://npr.brightspotcdn.com/bd/87/484943004c118e3b1046c162a194/burson-charles-bunny-2010-rect.jpg',
      'https://npr.brightspotcdn.com/9a/28/111d67cf4a7fa141a10602f15767/burson-charles-bunny-2010-square.jpg',
    ],
    'Julian Bush & Cathy Reilly': [
      'https://npr.brightspotcdn.com/4b/79/fae00ed14f67847f3930df92f9cc/bush-julian-reilly-cathy-ttt2019-rect.jpg',
      'https://npr.brightspotcdn.com/fb/64/9d3c3e354f7984225c975363e368/bush-julian-reilly-cathy-ttt2019-square.jpg',
    ],
    'Jennifer Williamson & Allan Connolly': [
      'https://npr.brightspotcdn.com/09/25/e3e68c504440bd39be6bd9e8f1c3/connolly-allan-2015-rect.jpg',
      'https://npr.brightspotcdn.com/9e/c3/ada9001b49eeb82b4458c47f8e62/connolly-allan-2015-square.jpg',
    ],
    'Joan & Jerry Cooper': [
      'https://npr.brightspotcdn.com/00/86/593eff154ad8888c7299cbbc2d65/cooper-joan-jerry-2012-rect.jpg',
      'https://npr.brightspotcdn.com/34/0d/d264c8444953980ab058c4d3df19/cooper-joan-jerry-2012-square.jpg',
    ],
    'Carmen Davis': [
      'https://npr.brightspotcdn.com/5c/25/d0d8db9d4fb38a23b6fdc5b7ee09/davis-carmen-2011-rect.jpg',
      'https://npr.brightspotcdn.com/6f/e9/444c49da4a32a857bc632243533e/davis-carmen-2011-square.jpg',
    ],
    'Doreen Dodson & Dana Spitzer': [
      'https://npr.brightspotcdn.com/27/66/ee7fed7f44119d191cee9bd7cb48/dodson-doreen-2010-rect.jpg',
      'https://npr.brightspotcdn.com/4c/73/ccb3cfb741b39e85324b502310ef/dodson-doreen-2010-square.jpg',
    ],
    'Barbara Eagleton': [
      'https://npr.brightspotcdn.com/bd/f4/cc814dc84b46b9c723d9e1985422/eagleton-barbara-ttt2018-rect.jpg',
      'https://npr.brightspotcdn.com/90/7c/b375429b45bf95c819d9e170d1de/eagleton-barbara-ttt2018-square.jpg',
    ],
    'Mike Giacaman': [
      'https://npr.brightspotcdn.com/d5/9c/99d07a9b4ac1a8d98cda44add93b/giacaman-michael-2011-rect.jpg',
      'https://npr.brightspotcdn.com/14/14/3ef8538a425fa1871e0df2385cbc/giacaman-michael-2011-square.jpg',
    ],
    'Jerry & Cathy Gidlow': [
      'https://npr.brightspotcdn.com/3a/b8/2e838ad8466a835179c3230d832f/gidlow-jerry-cathy-2012-rect.jpg',
      'https://npr.brightspotcdn.com/83/82/736e7034432db6c7a87bfbb0d662/gidlow-jerry-cathy-2012-square.jpg',
    ],
    'Barbara B. Goodman': [
      'https://npr.brightspotcdn.com/d6/3d/3984848447528ca3b61dbe8028d2/goodman-barbara-2012-rect.jpg',
      'https://npr.brightspotcdn.com/40/a1/a4b4e0ca40419b2072593276ff56/goodman-barbara-2012-square.jpg',
    ],
    'Linda & Michael Honigfort': [
      'https://npr.brightspotcdn.com/6e/6f/040835b542dfab49e1603c6288c9/honigfort-michael-linda-ttt2019-rect.jpg',
      'https://npr.brightspotcdn.com/16/54/ae2012174a11937b4f1f1acc488f/honigfort-michael-linda-ttt2019-square.jpg',
    ],
    'Karen Kalish': [
      'https://npr.brightspotcdn.com/4a/13/aa435c194bd29b302c7646bd2888/kalish-karen-2011-rect.jpg',
      'https://npr.brightspotcdn.com/21/3a/64cc357a4c1eb574d669f46197cb/kalish-karen-2011-square.jpg',
    ],
    'Diane & David Katzman': [
      'https://npr.brightspotcdn.com/f4/33/dbe06ba84cbca428269b3d2a4805/katzman-diane-david-ttt2019-rect.jpg',
      'https://npr.brightspotcdn.com/0d/3d/8904eb1c42c19d681928e7aa550f/katzman-diane-david-ttt2019-square.jpg',
    ],
    'Shelley & John Kerley': [
      'https://npr.brightspotcdn.com/51/af/11fd91f44ffc8183b167c0b0f813/kerley-shelley-john-ttt2019-rect.jpg',
      'https://npr.brightspotcdn.com/d2/3e/c486c6854cfcbf01bb2186307d46/kerley-shelley-john-ttt2019-square.jpg',
    ],
    'Ned & Sally Lemkemeier': [
      'https://npr.brightspotcdn.com/0e/ea/fa8054ea489e9ce1b2fea4e05d3c/lemkemeier-ned-sally-2013-rect.jpg',
      'https://npr.brightspotcdn.com/b6/80/55631e034a3b8e6db18b66422a0f/lemkemeier-ned-sally-2013-square.jpg',
    ],
    'Linda Locke and Rory Ellinger*': [
      'https://npr.brightspotcdn.com/65/e4/e99959ce4140a58383d0436b8c16/locke-linda-2013-rect.jpg',
      'https://npr.brightspotcdn.com/10/ec/320dd1c74c0f94b538ddb82b26ad/locke-linda-2013-square.jpg',
    ],
    'Judi & Sandy MacLean': [
      'https://npr.brightspotcdn.com/79/0a/1faec9554474b101b2bd8cb24bca/maclean-sandy-ttt2019-rect.jpg',
      'https://npr.brightspotcdn.com/00/e8/c99ab00744889ba168ce9d5b70d4/maclean-sandy-ttt2019-square.jpg',
    ],
    'Sam Fiorello & Rebecca Messbarger': [
      'https://npr.brightspotcdn.com/23/5c/a31952e14fa4acef0813ca127220/messbarger-rebecca-2010-rect.jpg',
      'https://npr.brightspotcdn.com/80/b9/f44c275b4294b4c8b9ceacf05046/messbarger-rebecca-2010-square.jpg',
    ],
    'Paul & Laura Miller': [
      'https://npr.brightspotcdn.com/d4/e8/9f06395d40a28e39b1a7057487eb/miller-paul-laura-ttt2019-rect.jpg',
      'https://npr.brightspotcdn.com/6f/c1/e4eb68b74455b80a27599c07bc97/miller-paul-laura-ttt2019-square.jpg',
    ],
    'Susan W. Nall': [
      'https://npr.brightspotcdn.com/db/9e/6986955e4aeeaf606e862b44d06d/nall-susie-2012-rect.jpg',
      'https://npr.brightspotcdn.com/a4/33/01cc08754319a7b348b860f0004a/nall-susie-2012-square.jpg',
    ],
    'David Ott & Elizabeth Streett-Ott': [
      'https://npr.brightspotcdn.com/ac/9b/d73c00af49a99cfd8f2e7950d538/ott-david-2014-rect.jpg',
      'https://npr.brightspotcdn.com/6f/35/30edb30d4e5b93cf9933def93191/ott-david-2014-square.jpg',
    ],
    'Shanti Parikh & Jason Wilson': [
      'https://npr.brightspotcdn.com/07/bc/b752282046f0b75dd32ecd32b47f/parikh-shanti-jason-wilson-ttt2019-rect.jpg',
      'https://npr.brightspotcdn.com/15/f6/183a3ca04957b792bf84738783df/parikh-shanti-jason-wilson-ttt2019-square.jpg',
    ],
    'Nathalie Pettus': [
      'https://npr.brightspotcdn.com/44/c0/26caf630461ea6591d79e9b59dda/pettus-nathalie-2014-rect.jpg',
      'https://npr.brightspotcdn.com/7c/60/7c129b78484295e7147535723468/pettus-nathalie-2014-square.jpg',
    ],
    'Florine & Joseph T. Porter, Jr.': [
      'https://npr.brightspotcdn.com/7c/71/ad7487a94db385d86cfae96f3237/porter-joseph-rect.jpg',
      'https://npr.brightspotcdn.com/ae/27/c298aafc4f3e85f8fad702551bdf/porter-joseph-square.jpg',
    ],
    'Emily Rauh Pulitzer': [
      'https://npr.brightspotcdn.com/9e/05/eb7c41634e3a9fc2c98966656b91/pulitzer-emily-2008-rect.jpg',
      'https://npr.brightspotcdn.com/e8/bb/684c628c4e839235ce42bdf24ff7/pulitzer-emily-2008-square.jpg',
    ],
    'Dorothy Reimer': [
      'https://npr.brightspotcdn.com/ac/03/ea3cc0894c7799619cbe567c496d/reimer-dorothy-2010-rect.jpg',
      'https://npr.brightspotcdn.com/88/86/12fc1c2846f19c763589f56393c6/reimer-dorothy-2010-square.jpg',
    ],
    'Laura and John Rossmann': [
      'https://npr.brightspotcdn.com/b2/25/3c468db84398a79ed84eb310f066/rossman-laura-rect.jpg',
      'https://npr.brightspotcdn.com/59/11/9445b9cc46678fd82420200a4a92/rossman-laura-square.jpg',
    ],
    'Russell K. Scott  & M. Loretta Lopinot*': [
      'https://npr.brightspotcdn.com/93/6b/79d0f03f4dc98d3fe1ea0d4582ae/russel-scott-lopinot-loretta-2013-rect.jpg',
      'https://npr.brightspotcdn.com/87/96/7eb2e8b34e1cb4a755f25b52bb2c/russel-scott-lopinot-loretta-2013-square.jpg',
    ],
    'Pam & Andy Schlichter': [
      'https://npr.brightspotcdn.com/8e/1b/396074a14587a6db6434af53cee2/schlichter-pam-andy-ttt2019-rect.jpg',
      'https://npr.brightspotcdn.com/ef/63/7109d8964d759a23aacb30f9998d/schlichter-pam-andy-ttt2019-square.jpg',
    ],
    'Diane Sher': [
      'https://npr.brightspotcdn.com/84/cc/0e6a28a84e02aedc7cee6d69ef8d/sher-diane-rect.jpg',
      'https://npr.brightspotcdn.com/16/f1/41893f8c4cdc9f79147e590f2810/sher-diane-square.jpg',
    ],
    'Susan & Peter Tuteur': [
      'https://npr.brightspotcdn.com/9d/c4/a40cde0841358e19ca9b4554879e/tutueur-peter-susan-rect.jpg',
      'https://npr.brightspotcdn.com/3f/a8/26a32ad94edd9a21b4f4c2a6991e/tutueur-peter-susan-square.jpg',
    ],
    'Susan Tuteur': [
      'https://npr.brightspotcdn.com/78/70/edb78871433ea353a54d0880b987/tuteur-susan-rect.jpg',
      'https://npr.brightspotcdn.com/62/99/5bd6c698468dbd7fc5316e771d67/tuteur-susan-square.jpg',
    ],
    'Susanne Franza Valdez': [
      'https://npr.brightspotcdn.com/02/42/1dc04c974a469de7ceac242f78c9/valdez-susanne-franza-rect.jpg',
      'https://npr.brightspotcdn.com/a9/0b/1a7ea5bd4911a37c0b9bfeae6a21/valdez-susanne-franza-square.jpg',
    ],
    'Josephine  and Richard Weil': [
      'https://npr.brightspotcdn.com/f1/fa/6f2990814b60a5b99fbc869afd2a/weil-richard-josephine-ttt2019-rect.jpg',
      'https://npr.brightspotcdn.com/1b/f7/9a57dc024ad5aa2fe5773bbcdaed/weil-richard-josephine-ttt2019-square.jpg',
    ],
    'Bruce & Trish Williams': [
      'https://npr.brightspotcdn.com/f7/57/f0416dfe40b6a43391cae0475e7a/williams-bruce-trish-2011-rect.jpg',
      'https://npr.brightspotcdn.com/ad/a0/ca90098a410a96d4f8f18013c41d/williams-bruce-trish-2011-square.jpg',
      'https://npr.brightspotcdn.com/01/c5/97fc366d40bb9697a316568cefae/williams-bruce-trish-2009-rect.jpg',
      'https://npr.brightspotcdn.com/25/2f/e5ef627a4f57abeedcff6a3e4b46/williams-bruce-trish-2009-square.jpg',
    ],
    // Bruce & Trish Williams #2: [
    // 'https://npr.brightspotcdn.com/f7/57/f0416dfe40b6a43391cae0475e7a/williams-bruce-trish-2011-rect.jpg',
    // 'https://npr.brightspotcdn.com/ad/a0/ca90098a410a96d4f8f18013c41d/williams-bruce-trish-2011-square.jpg',
    // ],
    'Judy & Lon Zimmerman': [
      'https://npr.brightspotcdn.com/9c/28/2357dda24652ab7413ac3a27ed7c/zimmerman-lon-rect.jpg',
      'https://npr.brightspotcdn.com/4e/07/d9a9d13545968f52ac1c5533b02c/zimmerman-lon-square.jpg',
    ],
  },

  manualTestimonials: {
    'Drs. Nanci & James Bobrow':                 'https://cpa.ds.npr.org/s278/audio/2022/03/bobrow-james-fa2016.mp3',
    // Jeffrey Carter:   'https://cpa.ds.npr.org/s278/audio/2022/03/carter-jeffrey-sp2016.mp3',
    // Cooper:   'https://cpa.ds.npr.org/s278/audio/2022/03/cooper-fa2014.mp3',
    'Adrienne Fly':                              'https://cpa.ds.npr.org/s278/audio/2022/03/fly-adrienne-fa2016.mp3',
    'Jeanne L. Heitman':                         'https://cpa.ds.npr.org/s278/audio/2022/03/heitman-jeanne-fa2017.mp3',
    'Mark & Margaret Holly':                     'https://cpa.ds.npr.org/s278/audio/2022/03/holly-mark-peggy-sp2016.mp3',
    'Barbara & Lawrence Holmes':                 'https://cpa.ds.npr.org/s278/audio/2022/03/holmes-barbara-lawrence-sp2016.mp3',
    'Diane & David Katzman':                     'https://cpa.ds.npr.org/s278/audio/2022/03/katzman-dianne-sp2016.mp3',
    // Dianne Katzman #2:   'https://cpa.ds.npr.org/s278/audio/2022/03/katzman-dianne-sp2016-2.mp3',
    'Paul & Francine Kravitz':                   'https://cpa.ds.npr.org/s278/audio/2022/03/kravitz-paul-francine-wi2018.mp3',
    // Tom Manche:   'https://cpa.ds.npr.org/s278/audio/2022/03/manche-tom-wi2017.mp3',
    'Charlotte & John Martin':                   'https://cpa.ds.npr.org/s278/audio/2022/03/martin-charlotte-john-fa2016.mp3',
    'Marie & Stephen Neville':                   'https://cpa.ds.npr.org/s278/audio/2022/03/neville-marie-stephen-wi2017.mp3',
    'Shanti Parikh & Jason Wilson':              'https://cpa.ds.npr.org/s278/audio/2022/03/parikh-shanti-fa2018.mp3',
    'Ms. Kimberly Rayford & Mr. Timothy Lucido': 'https://cpa.ds.npr.org/s278/audio/2022/03/rayford-kimberly-lucido-timothy-fa2018.mp3',
    'Tom Schlafly':                              'https://cpa.ds.npr.org/s278/audio/2022/03/schlafly-tom.mp3',
    'John & Deanna Simanowitz':                  'https://cpa.ds.npr.org/s278/audio/2022/03/simanowitz-john-deanna-sp2016.mp3',
    'Phyllis & Dr. Terry Weiss':                 'https://cpa.ds.npr.org/s278/audio/2022/03/weiss-phyllis-terry-sp2016.mp3',
    'Judy & Lon Zimmerman':                      'https://cpa.ds.npr.org/s278/audio/2022/03/zimmerman-judy-lon-fa2016.mp3',
  },

  featuredDonors: {
    landscape: [
      // 'Barbara Eagleton', // !missing
      'Judi & Sandy MacLean',
      'Linda & Michael Honigfort',
      'Pam & Andy Schlichter',
      'Shelley & John Kerley',
      'Steven Novik & Cathy Barancik',
    ],
    portrait: [
      'Bruce & Trish Williams',
      'Josephine  and Richard Weil',
      'Paul & Laura Miller',
      'Susan & Peter Tuteur',
    ],
    testimonials: [
      'Diane & David Katzman',
      // 'Shanti Parikh & Jason Wilson',
    ],
  },
}

export default project
