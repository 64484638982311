import { formatCharacters, containsLetters, getRandomItems } from './helpers'
import { by } from './data-helpers'

function getSearchResultsFromInput(target, donors) {
  const limit = 10
  const matches = []

  const people = donors
    .map((person, index) => {
      const tidyName = person.name
        .toLowerCase()
        .replace(/[^a-z ]/, '')
        .replace(/\s+/, ' ')
        .trim()

      return { name: person.name, thumbnail: person.thumbnail, tidyName, index }
    })

  function scorePerson(person) {
    if (!target) return 0
    const { tidyName } = person

    const pattern = target
      .toLowerCase()
      .replace(/[^a-z ]/, '')
      .replace(/\s+/, ' ')
      .trim()
      .replace(' ', '.*')

    const startsPattern = new RegExp(`^${pattern}`)
    if (startsPattern.test(tidyName)) return 0

    const looseStartsPattern = new RegExp(`\\s${pattern}`)
    if (looseStartsPattern.test(tidyName)) return 1

    const containsPattern = new RegExp(pattern)
    if (containsPattern.test(tidyName)) return 2

    return -1
  }

  function updateMatches(person) {
    const { score } = person
    if (score === -1) return

    matches.sort(by('score'))

    const arrayIsNotFull = (matches.length < limit)
    const scoreIsBetter = (matches[matches.length - 1] > score)

    if (arrayIsNotFull) {
      matches.push(person)
    }
    else if (scoreIsBetter) {
      matches.pop()
      matches.push(person)
    }
  }

  function matchesIsOptimal() {
    return matches.length === limit && matches.every(match => match.score === 0)
  }
  for (let i = 0; i < people.length; i += 1) {
    const person = people[i]
    person.score = scorePerson(person)

    updateMatches(person)

    if (matchesIsOptimal()) break  // Break early if limit reached with all perfect matches
  }

  return matches.sort(by('score', 'name', 'index'))
}

function onSearch(state, router) {
  let results = []

  const { donors } = state
  if (!donors.length) return

  const inputElement = document.querySelector('.search_input')
  const resultsElement = document.querySelector('[data-search-results]')
  if (!inputElement || !resultsElement) return

  function onChange() {
    const { value } = inputElement

    // if there are no alphabet characters, use previous result
    // if there are no alphabet characters and we don't have a previous result,
    // get a random set of donors to display
    // if there are alphabet characters, run the search algorithm
    if (!containsLetters(value)) {
      if (!results.length) {
        results = getRandomItems(donors, 10)
          .sort(by('name', 'index'))
      }
    }
    else {
      results = getSearchResultsFromInput(value, donors)
    }

    resultsElement.innerHTML = ''
    results.forEach(donor => {
      const { name, index, thumbnail } = donor
      resultsElement.insertAdjacentHTML('beforeend', `
        <li class="search_results_item">
          <a class="search_results_link" href="/donor/${index}" data-navigo>
            <img src="${thumbnail}">
            <div>${formatCharacters(name, { className: 'muted' })}</div>
          </a>
        </li>`)
    })
    router.updatePageLinks()
  }

  onChange()
  inputElement.addEventListener('input', onChange)
  inputElement.addEventListener('update-input', onChange)
}

export default onSearch
