import { ButtonNavigation, SearchModal, MemberGroupModal } from '../components'

class Impact {
  render() {
    return `
    ${SearchModal().render()}
    ${MemberGroupModal().render()}
    <div id="impact" class="basic-page">
      <main>
      </main>
      <footer>
        ${ButtonNavigation().render()}
      </footer>
    </div>
    `
  }
}

export default Impact
