import { icons } from '../../store'

class MemberGroupModal {
  render() {
    return `
    <div id="member-group-modal" class="overlay">
      <figure class="modal modal-small">
        <section class="modal_heading">
          <span class="modal_title">Donors</span>
          <a class="modal_link" href="" data-navigo>
            <em>Learn More</em> ${icons['angle-bracket-right-small']()}
          </a>
        </section>
        <section class="modal_description"></section>
        <section class="background-alt modal_donor-list">
          <h3><span class="donor-list-title"></span></h3>
          <ul class="donor-list"></ul>
        </section>
      </figure>
    </div>
    `
  }
}

export default MemberGroupModal
