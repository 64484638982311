// here, "Stripped Case" means lowercase with only alphanumeric characters
// it is used to compare strings

const toStrippedCase = string => {
  if (typeof string !== 'string') {
    throw new TypeError(
      `Argument must be of type "string". Instead got ${typeof string}`,
    )
  }

  return string.toLowerCase().replace(/[^a-zA-Z0-9]/g, '')
}

export default toStrippedCase
