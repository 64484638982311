/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import { donorGroups } from '../store'
import getMembersFromGroupName from './getMembersFromGroupName'
import { by } from './data-helpers'
import {
  formatCharacters,
  getRandomItems,
  queryEach,
  removeClass,
  toKebabCase,
} from './helpers'

const numberOfDonorsToDisplay = 12

function configureMemberGroupLabels(state, router) {
  const labels = document.querySelectorAll('[data-member-type]')
  const groupModal = document.querySelector('#member-group-modal')
  const donorsList = document.querySelector('.donor-list')

  if (!labels.length || !groupModal) return

  labels.forEach(label => {
    const memberGroup = label.getAttribute('data-member-type')

    label.addEventListener('click', () => {
      setTimeout(() => {
        removeClass('.overlay', 'is-active')

        const group = donorGroups.find(level => level.name === memberGroup) || {}
        const name = group.name || memberGroup
        const plural = group.plural || 'Members'

        const donorSample = getMembersFromGroupName(state.donors, name)
        const donors = getRandomItems(
          donorSample,
          Math.min(numberOfDonorsToDisplay, donorSample.length),
        )
          .sort(by('name', 'index'))

        const backgroundGradient = photo => `
          linear-gradient(0, rgba(35, 64, 147, 0.9), rgba(35, 64, 147, 0.9)), url(${photo})`

        // update information on the modal
        queryEach('.modal_heading',
          heading => heading.style.backgroundImage = backgroundGradient(group.photo),
          groupModal)

        queryEach('.modal_link',
          link => link.setAttribute('href', `/group/${toKebabCase(name)}`),
          groupModal)

        queryEach('.modal_title',
          title => title.textContent = name,
          groupModal)

        queryEach('.donor-list-title',
          title => title.textContent = plural,
          groupModal)

        queryEach('.modal_description',
          description => description.innerHTML = group.description || '',
          groupModal)

        // add the list of donors to the modal
        donorsList.innerHTML = ''
        donors.forEach(donor => donorsList.insertAdjacentHTML('beforeend', `
          <li>
            <a href="/donor/${donor.index}" data-navigo>
              <img src="${donor.thumbnail}">
              <div>${formatCharacters(donor.name, { className: 'muted' })}</div>
            </a>
          </li>`))

        // display the modal
        groupModal.classList.add('is-active')
        router.updatePageLinks()
      }, 0)
    })
  })
}

export default configureMemberGroupLabels
