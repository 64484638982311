/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */

import { project }            from '../store'
import { getRandomItems }     from './helpers'

const { manualPhotos, manualTestimonials, paths } = project

function interceptDonors(_donors) {
  const donors = [..._donors]

  const donorNamesForPhotos = Object.keys(manualPhotos)
  const donorNamesForAudio  = Object.keys(manualTestimonials)

  donors.forEach(donor => {
    // set photos and thumbnail photos
    if (donorNamesForPhotos.includes(donor.name)) {
      const [photo, thumbnail] = manualPhotos[donor.name]
      donor.photo = photo
      donor.thumbnail = thumbnail
    }
    else {
      donor.photo = getRandomItems(paths.defaultPhotos, 1)
      donor.thumbnail = getRandomItems(paths.defaultThumbnails, 1)
    }

    // set audio testimonials
    if (donorNamesForAudio.includes(donor.name)) {
      donor.audioTestimonial = manualTestimonials[donor.name]
    }
  })
  return donors
}

export default interceptDonors
