/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */

// Navigo compares current route to lastRouteResolved
// when they match, router does not refresh page contents
// We can force a refresh by invalidating the lastRoute

function refreshPage(router) {
  const currentPage = router.lastRouteResolved().url
  router._lastRouteResolved = null
  router.navigate(currentPage)
}

export default refreshPage
