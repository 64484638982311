import { donorGroups } from '../store'
import { toStrippedCase } from './helpers'

const getGivingLevelFromName = name => {
  const match = donorGroups.find(level => toStrippedCase(level.name) === toStrippedCase(name))
  if (!match) return donorGroups.find(level => level.name === 'default')
  return match
}

export default getGivingLevelFromName
