/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */
import { homepageTiles as tiles } from '../../store'
import { SearchModal, MemberGroupModal, Tile } from '../components'

class HomeScreen {
  constructor(state) {
    this.state = state
  }

  render() {
    const tilesHTML = tiles.reduce((HTML, tile) => {
      const instance = Tile(tile)
      return HTML + instance.render()
    }, '')

    return `
      ${SearchModal().render()}
      ${MemberGroupModal().render()}
      <main id="home">
        ${tilesHTML}
      </main>
    `
  }
}

export default HomeScreen
