const defaultPhoto              = 'https://npr.brightspotcdn.com/41/cf/130ecfe942d7b9951a6fe73b6de5/default.jpg'
const cornerstoneSocietyPhoto   = 'https://npr.brightspotcdn.com/2f/53/70d5fb2a43b487ebcad0b7e9432f/cornerstone-signup.jpg'
const cornerstonePatronPhoto    = 'https://npr.brightspotcdn.com/54/32/579a1ed642439509e326fac3c3c5/cornerstone-lobby.jpg'
const cornerstoneVisionaryPhoto = 'https://npr.brightspotcdn.com/9e/a5/0d1778f545ff9b3c66639ed608e3/cornerstone-dinner.jpg'
const generationListenPhoto     = 'https://npr.brightspotcdn.com/a8/ca/4111e52c4748b0be32a7e5044b81/generation-listen.jpg'
const twentyYearClubPhoto       = 'https://npr.brightspotcdn.com/8d/54/2184f3e944bb85fae2ac59793d47/twenty-year-club.jpg'

const donorGroups = [
  {
    name: 'Cornerstone Society',
    plural: 'Cornerstone Society Donors',
    description: `
    <p>Cornerstone Society members support the station’s annual operations and enable innovative new projects. This active group of engaged donors comes together frequently with station leaders, news and program staff to discuss ideas and issues of current concern.</p>
    <p>Cornerstone Society donors contribute an annual gift of $1,200 or more.</p>`,
    photo: cornerstoneSocietyPhoto,
  },
  {
    name: 'Cornerstone Patron',
    plural: 'Cornerstone Patron Donors',
    description: `
    <p>Cornerstone Society members support the station’s annual operations and enable innovative new projects. This active group of engaged donors comes together frequently with station leaders, news and program staff to discuss ideas and issues of current concern.</p>
    <p>Cornerstone Patron donors contribute an annual gift of $2,400 or more.</p>`,
    photo: cornerstonePatronPhoto,
  },
  {
    name: 'Cornerstone Leader',
    plural: 'Cornerstone Leader Donors',
    description: `
    <p>Cornerstone Society members support the station’s annual operations and enable innovative new projects. This active group of engaged donors comes together frequently with station leaders, news and program staff to discuss ideas and issues of current concern.</p>
    <p>Cornerstone Leader donors contribute an annual gift of $5,000 or more.</p>`,
    photo: cornerstonePatronPhoto,
  },
  {
    name: 'Cornerstone Visionary',
    plural: 'Cornerstone Visionary Donors',
    description: `
    <p>Cornerstone Society members support the station’s annual operations and enable innovative new projects. This active group of engaged donors comes together frequently with station leaders, news and program staff to discuss ideas and issues of current concern.</p>
    <p>Cornerstone Visionary donors contribute an annual gift of $10,000 or more.</p>`,
    photo: cornerstoneVisionaryPhoto,
  },
  {
    name: 'Carl Kasell Circle',
    plural: 'Carl Kasell Circle Donors',
    description: `
    <p>Cornerstone Society members support the station’s annual operations and enable innovative new projects. This active group of engaged donors comes together frequently with station leaders, news and program staff to discuss ideas and issues of current concern.</p>
    <p>Carl Kasell Circle donors contribute an annual gift of $25,000 or more.</p>`,
    photo: cornerstoneVisionaryPhoto,
  },
  {
    name: 'Beacon Merger Fund Donor',
    plural: 'Beacon Merger Fund Donors',
    description: `
    <p>Donors to this special fund ensured the successful, ground-breaking, 2012 merger of St. Louis’ two public media news organizations, St. Louis Public Radio and The Beacon, into a single, multi-faceted non-profit media organization to better serve our regional community.</p>`,
    photo: defaultPhoto,
  },
  {
    name: 'Greatness at Grand Center Campaign',
    plural: 'Greatness at Grand Center Campaign Donors',
    description: `
    <p>Generous contributors to the Greatness at Grand Center campaign have ensured a forum for the future – the UMSL at Grand Center building, which opened in 2012.</p>`,
    photo: defaultPhoto,
  },
  {
    name: 'Cornerstone Society Charter Member',
    plural: 'Cornerstone Society Charter Members',
    description: `
    <p>Charter Member: In 1998, St. Louis Public Radio established the Cornerstone Society to honor generous donors contributing $1,000 or more annually.</p>`,
    photo: defaultPhoto,
  },
  {
    name: 'Generation Listen',
    plural: 'Generation Listen Members',
    description: `
    <p>Generation Listen is a community of public radio members who share a passion for creating a more informed and civically engaged public.</p>`,
    photo: generationListenPhoto,
  },
  {
    name: 'Legacy Society',
    plural: 'Legacy Society Donors',
    description: `
    <p>The St. Louis Public Radio Legacy Society honors the philanthropic leadership and vision of individuals who have provided for the future of public radio in our region by including the station in their estate plans.</p>`,
    photo: defaultPhoto,
  },
  {
    name: '20 Year Club',
    plural: '20 Year Club Members',
    description: `
    <p>The 20 Year Member’s club honors the special group of donors who have stood with us for more than two decades.</p>`,
    photo: twentyYearClubPhoto,
  },
  {
    name: 'Board Member',
    plural: 'Board Members',
    description: `
    <p>The Friends of St. Louis Public Radio Board of Directors furthers the mission and goals of St. Louis Public Radio. Board members are chosen on the basis of their willingness to contribute their time, talent and financial support to St. Louis Public Radio. The Board plays an important role in fundraising to support the work of St. Louis Public Radio and also works to assess and provide feedback and guidance about community needs.</p>`,
    photo: twentyYearClubPhoto,
  },
  {
    name: 'Past Board Member',
    plural: 'Past Board Members',
    description: `
    <p>The Friends of St. Louis Public Radio Board of Directors furthers the mission and goals of St. Louis Public Radio. Board members are chosen on the basis of their willingness to contribute their time, talent and financial support to St. Louis Public Radio. The Board plays an important role in fundraising to support the work of St. Louis Public Radio and also works to assess and provide feedback and guidance about community needs.</p>`,
    photo: twentyYearClubPhoto,
  },
  {
    name: 'Donald H. Driemeier Endowment',
    plural: 'Donald H. Driemeier Endowment Donors',
    description: `
    <p>Donations to the Donald H. Driemeier Endowment provide for the continuing operation of St. Louis Public Radio for the next generation of public radio fans.</p>`,
    photo: twentyYearClubPhoto,
  },
  {
    name: 'Classical Music Endowment',
    plural: 'Classical Music Endowment Donors',
    description: `
    <p>This endowment ensures classical music can continue on public radio and be available for the next generation of future classical music fans.</p>`,
    photo: twentyYearClubPhoto,
  },
  {
    name: 'default',
    plural: 'Members',
    description: '',
    photo: defaultPhoto,
  },
]

export default donorGroups
