/* eslint-disable brace-style */
/* eslint-disable no-param-reassign */
import { project, settings } from '../store'
import { shuffleArray, queryEach, randomNumberInclusive } from './helpers'

const { landscape, portrait, testimonials } = project.featuredDonors
const { enableDonorSwapping } = settings.flags

const intervals = []
let activeDonor

function getDonorFromName(donors, name) {
  return donors.find(donor => donor.name === name)
}

function setTile(element, donor) {
  const stillOnHomePage = document.querySelector('#home')
  if (!stillOnHomePage) {
    intervals.forEach(interval => clearInterval(interval))
    // console.info('all intervals are cleared')
    return
  }

  const { isFrozen } = element.dataset
  if (isFrozen !== undefined || !donor) return

  const { photo, name, index, groups, audioTestimonial } = donor
  element.dataset.donor = name

  const memberTypeHTML = (groups || []).reduce((HTML, type) => (
    HTML + `<button data-member-type="${type}">${type}</button>`
  ), '')

  queryEach('.tile_image',
    image => {
      image.insertAdjacentHTML('beforebegin', `<img class="tile_image is-active" src="${photo}">`)
      image.classList.remove('is-active')
      setTimeout(() => image.remove(), 1000)
    },
    element)
  queryEach('.member-name, .player_title span',
    title => { title.innerText = name },
    element)
  queryEach('.tile_donor-link',
    link => { link.href = `/donor/${index}` },
    element)
  queryEach('.member-types',
    list => { list.innerHTML = memberTypeHTML },
    element)
  queryEach('.embedded-audio-bar',
    figure => figure.setAttribute('data-player', audioTestimonial),
    element)
}

function configureHomeScreenTiles(state, router, initializePlayer) {
  const home = document.querySelector('#home')
  const { donors } = state

  if (!home || !donors.length) return

  // Set up featuredDonors values
  // This approach with shuffled Arrays and indexes will allow us to create
  // random, non-repeating functionality
  const featuredDonors = {
    landscape:   shuffleArray(landscape.map(name => getDonorFromName(donors, name))),
    portrait:    shuffleArray(portrait.map(name => getDonorFromName(donors, name))),
    testimonial: shuffleArray(testimonials.map(name => getDonorFromName(donors, name))),
  }
  const indexes = {
    landscape: 0,
    portrait: 0,
    testimonial: 0,
  }

  function getNextDonor(orientation) {
    const donorSet = featuredDonors[orientation]
    const currentIndex = indexes[orientation]

    const donor = donorSet[currentIndex]
    indexes[orientation] += 1

    if (indexes[orientation] + 1 >= donorSet.length) {
      indexes[orientation] = 0
    }

    // Troubleshoot missing donors
    if (!donor || !donor.name) {
      console.info({ donorSet, currentIndex, donor })
      return donor
    }

    // if next donor is our active donor, skip it
    return (donor.name === activeDonor)
      ? getNextDonor(orientation)
      : donor
  }

  // Gather references to tiles in DOM
  const allTiles         = document.querySelectorAll('.tile')
  const landscapeTiles   = document.querySelectorAll('.tile[data-orientation="landscape"]')
  const portraitTiles    = document.querySelectorAll('.tile[data-orientation="portrait"]')
  const testimonialTiles = document.querySelectorAll('.tile[data-orientation="testimonial"]')

  // console.log('starting intervals')

  landscapeTiles.forEach((tile, index) => {
    setTile(tile, getNextDonor('landscape'))

    if (enableDonorSwapping === true) {
      const thisInterval = setInterval(() => {
        setTile(tile, getNextDonor('landscape'))
        router.updatePageLinks()
      },
      randomNumberInclusive(10000, 15000) + (index * 500))
      intervals.push(thisInterval)
    }
  })

  portraitTiles.forEach((tile, index) => {
    setTile(tile, getNextDonor('portrait'))

    if (enableDonorSwapping === true) {
      const thisInterval = setInterval(() => {
        setTile(tile, getNextDonor('portrait'))
        router.updatePageLinks()
      },
      randomNumberInclusive(10000, 15000) + (index * 500))
      intervals.push(thisInterval)
    }
  })

  testimonialTiles.forEach((tile, _index) => {
    setTile(tile, getNextDonor('testimonial'))
    initializePlayer(state)
  })

  // When a tile has been activated, it remains active until another tile is interacted with
  allTiles.forEach(tile => tile.addEventListener('click', event => {
    const { currentTarget } = event
    allTiles.forEach(element => element.removeAttribute('data-is-frozen'))
    currentTarget.dataset.isFrozen = ''
    activeDonor = currentTarget.dataset.donor
  }))
}

export default configureHomeScreenTiles
