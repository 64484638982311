import { renderComponent } from '../../lib'

import _Donor        from './Donor'
import _Group        from './Group'
import _HomeScreen   from './HomeScreen'
import _Impact       from './Impact'
import _Testimonials from './Testimonials'

const Donor        = renderComponent(_Donor)
const Group        = renderComponent(_Group)
const HomeScreen   = renderComponent(_HomeScreen)
const Impact       = renderComponent(_Impact)
const Testimonials = renderComponent(_Testimonials)

export { Donor }
export { Group }
export { HomeScreen }
export { Impact }
export { Testimonials }
