import { icons } from '../../store'

class ButtonNavigation {
  render() {
    return `
    <nav id="button-navigation">
      <button class="nav_button" data-back-button>
        ${icons.back()}
        Go Back
      </button>
      <a class="nav_button" href="/" data-navigo>
        ${icons.home()}
        Return to Home
      </a>
      <button class="nav_button" data-search-button>
        ${icons.search()}
        Find a Donor
      </button>
    </nav>
    `
  }
}

export default ButtonNavigation
