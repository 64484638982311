import { icons } from '../../store'

class SearchModal {
  render() {
    return `
    <div id="search-modal" class="overlay">
      <figure class="modal">
        <section class="modal_heading">
          ${icons.search()}
          Find A Donor
        </section>
        <section class="search-modal_keyboard background-alt">
          <div class="simple-keyboard"></div>
        </section>
        <section class="search-modal_panel">
          <label class="search_input-shell">
            <i class="search_input_icon">${icons.search()}</i>
            <input type="text" class="search_input" spellcheck="false">
          </label>
          <ul class="search-modal_results" data-search-results></ul>
        </section>
      </figure>
    </div>
    `
  }
}

export default SearchModal
