import { formatTimestamp, randomNumberInclusive } from '../../lib/helpers'
import { icons } from '../../store'


const defaultOptions = {
  showTitleBar: false, // if true, display a bar with donor name above player
  completion: 0, // % percentage of the bar completed, or 'random' to randomize progress
  duration: 'empty', // time in seconds that duration should be, or 'random' to randomize duration, or 'empty' for ×:××
}

class AudioBar {
  constructor(donor, options = {}) {
    this.donor = donor
    this.options = { ...defaultOptions, ...options }
  }

  render() {
    const { name, audioTestimonial } = this.donor
    let { completion, duration } = this.options
    const { showTitleBar } = this.options

    if (duration === 'random') {
      duration = randomNumberInclusive(0, 599) // max of 9:59 for max 3-digit time
    }
    if (completion === 'random') {
      completion = randomNumberInclusive(0, 100) / 100
    }
    if (completion > 1) {
      completion /= 100
    }

    return `
    <figure class="embedded-audio-bar player" data-player="${audioTestimonial || ''}">
      ${showTitleBar ? `<h2 class="player_title">A testimonial by <span>${name}</span></h2>` : ''}

      <div class="player_controls">
        <button class="player_control" data-control-play-pause>${icons['play-mini']()}</button>

        <div class="player_progress">
          <time class="player_label">${duration === 'empty' ? '× ××' : formatTimestamp(completion * duration)}</time>
          <div class="player_progress_bar"><div style="width: ${completion * 100}%"></div></div>
          <time class="player_label">${duration === 'empty' ? '× ××' : formatTimestamp(duration)}</time>
        </div>

        <button class="player_control control-small" data-control-rewind>
          ${icons['rewind-fifteen-mini']()}
        </button>
        <button class="player_control control-small" data-control-advance>
          ${icons['advance-fifteen-mini']()}
        </button>
      </div>
    </figure>
    `
  }
}

export default AudioBar
