import { removeClass } from './helpers'

function configureModalDestruction() {
  document.body.addEventListener('click', event => {
    const target = event.target.closest('.modal')

    if (target === null) {
      removeClass('.overlay', 'is-active')
    }
  })
}

export default configureModalDestruction
