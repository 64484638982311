/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
import AudioPlayer from './AudioPlayer'
import { icons } from '../store'
import { formatTimestamp, queryEach } from './helpers'

const playIcon = icons['play-mini']
const pauseIcon = icons['pause-mini']

function enableAudioPlayers(state) {
  const allPlayers = state.audioPlayers

  // Reset Audio Players
  state.audioPlayers.forEach(object => {
    object.player.reset()
  })
  state.audioPlayers.length = 0

  const audioPlayers = document.querySelectorAll('[data-player]')
  if (!audioPlayers.length) return null

  audioPlayers.forEach(element => {
    const source = element.dataset.player
    const player = new AudioPlayer({ sources: [source], errorMode: false, timeout: -1 })

    player.sync('playerready', 'loadPlayerUI', _player => {
      const { currentTime, duration } = _player.audio

      queryEach( // current time
        '.player_label:first-child',
        label => label.textContent = formatTimestamp(currentTime),
        element,
      )
      queryEach( // duration
        '.player_label:last-child',
        label => label.textContent = formatTimestamp(duration),
        element,
      )
      queryEach( // progressBar
        '.player_progress_bar > div',
        bar => bar.style.width = 0,
        element,
      )
    })

    player.sync('timeupdate', 'updateProgressBar', _player => {
      const { currentTime, duration } = _player.audio

      queryEach( // update progres bar
        '.player_progress_bar > div',
        bar => {
          const progress = (currentTime / duration) * 100
          bar.style.width = `${progress}%`
        },
        element,
      )

      queryEach( // current time
        '.player_label:first-child',
        label => label.textContent = formatTimestamp(currentTime),
        element,
      )
    })

    queryEach(
      '.player_control[data-control-play-pause]',
      control => control.addEventListener('click', () => player.togglePlayPause()),
      element,
    )

    queryEach(
      '.player_control[data-control-rewind]',
      control => control.addEventListener('click', () => {
        const { audio } = player
        audio.currentTime = (audio.currentTime > 15)
          ? audio.currentTime -= 15
          : audio.currentTime = 0
      }),
      element,
    )

    queryEach(
      '.player_control[data-control-advance]',
      control => control.addEventListener('click', () => {
        const { audio } = player
        audio.currentTime = (audio.currentTime < audio.duration - 15)
          ? audio.currentTime += 15
          : audio.currentTime = audio.duration
      }),
      element,
    )

    player.sync('play', 'updatePlayerButton', _audioPlayer => {
      queryEach(
        '.player_control[data-control-play-pause]',
        control => control.innerHTML = pauseIcon(),
        element,
      )
    })

    player.sync('pause', 'updatePlayerButton', _audioPlayer => {
      queryEach(
        '.player_control[data-control-play-pause]',
        control => control.innerHTML = playIcon(),
        element,
      )
    })

    player.sync('play', 'playOnlyOneFile', _audioPlayer => {
      const otherPlayers = allPlayers.filter(_player => _player.player !== _audioPlayer)
      otherPlayers.forEach(thing => thing.player.pause())
    })

    allPlayers.push({ element, player })
  })

  window.allPlayers = allPlayers
  return allPlayers
}

export default enableAudioPlayers
