/* eslint-disable no-param-reassign */
import { getNthLastItem } from './helpers'

function configureBackButton(state, router) {
  if (!state) return
  // clone history array so we don't have to deal with changes to history from navigating
  const history = [...state.history]
  // remove current page
  history.pop()

  const backButtons = document.querySelectorAll('[data-back-button]')
  if (!backButtons) return

  backButtons.forEach(button => {
    button.addEventListener('click', () => {
      state.history = history
      router.navigate(getNthLastItem(history))
    })
  })
}

export default configureBackButton
