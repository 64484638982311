const homepageTiles = [
  {
    key: 'a',
    type: 'photo',
    orientation: 'landscape',
    size: 'large',
  },
  {
    key: 'b',
    type: 'photo',
    orientation: 'landscape',
    size: 'small',
  },
  {
    key: 'c',
    type: 'logo',
  },
  {
    key: 'd',
    type: 'photo',
    orientation: 'portrait',
    size: 'small',
  },
  {
    key: 'e',
    type: 'photo',
    orientation: 'landscape',
    size: 'small',
  },
  {
    key: 'f',
    type: 'link',
    action: 'search',
  },
  {
    key: 'g',
    type: 'testimonial',
    orientation: 'landscape',
    size: 'medium',
  },
  {
    key: 'h',
    type: 'photo',
    orientation: 'portrait',
    size: 'large',
  },
  {
    key: 'i',
    type: 'link',
    action: 'listen',
  },
  {
    key: 'j',
    type: 'photo',
    orientation: 'landscape',
    size: 'medium',
  },
  {
    key: 'k',
    type: 'other',
    content: `
    <p>Welcome to St. Louis Public Radio!</p>`,
  },
]

export default homepageTiles
