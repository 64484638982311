/* eslint-disable operator-linebreak */
/* eslint-disable prefer-destructuring */
import { isTrue, parseCSV, toCapitalCase } from './helpers'

// Accept Donor CSV Export and parse it to a JavaScript object

function parseDonorCSV(CSV) {
  if (!CSV) {
    throw new Error('Argument is required')
  }
  if (typeof CSV !== 'string') {
    throw new ReferenceError(`Argument must be of type "string". Instead got ${typeof CSV}`)
  }

  const donorsObjects = parseCSV(CSV)
    // parseCSV has a bug in which parseCSV(...)[0] = [""]
    // parseCSV(...)[1] is the header record
    .slice(2)
    .map(array => {
      const [ // fields from the CSV file
        majorDonorFlag,
        seqName,
        cornerstoneNameRecognition,
        _cornerstoneMember,
        levelCode,
        cornerstoneCharterMember,
        capitalCampaignInformation,
        legacySociety,
        boardMemberStatus,
        generationListenMember,
        twentyYearClub,
        city,
        stateProv,
        endowmentDonor,
        beaconMergerDonor,
        _primaryPictureFilePath,
        _activityText,
      ] = array

      const donor = {
        name: cornerstoneNameRecognition || seqName || 'Anonymous',
        isActive: (majorDonorFlag.toLowerCase() === 'active'),
        groups: [],
        city,
        stateProv,
      }

      // Make sure name isn't uppercase
      if (donor.name === donor.name.toUpperCase()) {
        donor.name = toCapitalCase(donor.name)
      }

      // Make sure name doesn't contain disallowed characters
      if (donor.name.includes('*')) {
        donor.name = donor.name.replace(/\*/g, '')
      }

      // Get Member Types from properties
      if (isTrue(beaconMergerDonor))           donor.groups.push('Beacon Merger Fund Donor')
      if (isTrue(capitalCampaignInformation))  donor.groups.push('Greatness at Grand Center Capaign')
      if (isTrue(cornerstoneCharterMember))    donor.groups.push('Cornerstone Society Charter Member')
      if (isTrue(generationListenMember))      donor.groups.push('Generation Listen')
      if (isTrue(legacySociety))               donor.groups.push('Legacy Society')
      if (twentyYearClub)                      donor.groups.push('20 Year Club')

      // Get Member Types from levelCode
      if (levelCode) {
        const code = levelCode.toLowerCase()
        if (code.includes('donor'))            donor.groups.push('Cornerstone Society')
        if (code.includes('patron'))           donor.groups.push('Cornerstone Patron')
        if (code.includes('leader'))           donor.groups.push('Cornerstone Leader')
        if (code.includes('visionary'))        donor.groups.push('Cornerstone Visionary')
        if (code.includes('carl kasell'))      donor.groups.push('Carl Kasell Circle')
      }

      // Get Member Types from endowmentDonor
      if (endowmentDonor) {
        const endowment = endowmentDonor.toLowerCase()
        if (endowment.includes('classical music')) donor.groups.push('Classical Music Endowment')
        else if (endowment.includes('driemeier'))  donor.groups.push('Donald H. Driemeier Endowment')
        else                                       donor.groups.push(endowmentDonor)
      }

      // Get Board Status from boardMemberStatus
      if (boardMemberStatus) {
        const status = boardMemberStatus.toLowerCase()

        // We only want 1 board status per donor, but the data is dirty
        // In order of precedence: Current Member > Emeritus Member > Past Member
        let boardStatus = ''
        if (status.includes('past board member'))      boardStatus = 'Past Board Member'
        if (status.includes('emeritus board member'))  boardStatus = 'Emeritus Board Member'
        if (status.includes('current board member'))   boardStatus = 'Current Board Member'

        donor.boardStatus = boardStatus
      }

      // HACK to separate Past Board Member
      // Data is dirty, so it's possible to be both a Past and Current board member
      // that is why the previous section overwrit boardStatus with each check
      // If donor was a Past Board Member, remove their boardStatus and add it as a group
      if (donor.boardStatus === 'Past Board Member') {
        donor.boardStatus = undefined
        donor.groups.push('Past Board Member')
      }
      if (donor.boardStatus) donor.groups.push('Board Member')

      // Year achieving Twenty-Year status is last 4 digits of twentyYearClub
      if (twentyYearClub) {
        const twentyYearDate = twentyYearClub.match(/([0-9]{4})$/)
        if (twentyYearDate) donor.twentyYearDate = twentyYearDate[1]
      }

      return donor
    })

  return donorsObjects
    .filter(donor => (
      donor.isActive &&
      donor.name &&
      // Exclude Anonymous donors
      // look for the inclusion of "anonymous" because data may be dirty
      !donor.name.toLowerCase().includes('anonymous')
    ))
}

export default parseDonorCSV
