function generateGroupList(donor) {
  if (!donor) {
    throw new ReferenceError(
      'Argument is required',
    )
  }
  const { groups, boardStatus } = donor
  if (!groups || !groups.length) return ''

  const groupHTML = groups.reduce((HTML, _group) => {
    const group = (_group === 'Board Member')
      ? boardStatus
      : _group

    return HTML + `
      <li>
        <button data-member-type="${_group}">
          ${group}
        </button>
      </li>
    `
  }, '')

  return groupHTML
}

export default generateGroupList
