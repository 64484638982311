/* eslint-disable class-methods-use-this */
import {
  AudioBar,
  ButtonNavigation,
  SearchModal,
  MemberGroupModal,
} from '../components'

import {
  formatCharacters,
  generateGroupList,
} from '../../lib'

class Donor {
  constructor(_state, props) {
    const [donor] = props
    this.donor = donor || {}
  }

  renderMemberTypes(types = []) {
    return types.reduce((HTML, type) => HTML + `
      <li>
        <button class="member-type" data-member-type="${type}">
          ${type}
        </button>
      </li>
    `, '')
  }

  render() {
    const { donor } = this
    const { name, photo, audioTestimonial } = donor

    return `
    ${SearchModal().render()}
    ${MemberGroupModal().render()}
    <style>
      #donor figure::before { background-image: url('${photo}') }
    </style>
    <div id="donor" class="basic-page">
      <main>
        <figure class="main-photo">
          <img src="${photo}" alt="photo of ${name || ''}">
        </figure>
        <section class="donor-info">
          ${audioTestimonial ? AudioBar(donor).render() : ''}
          <h3 class="donor-info_heading">
            ${name ? formatCharacters(name, { className: 'muted' }) : ''}
          </h3>
          <ul class="donor-info_member-types">
            ${generateGroupList(donor)}
          </ul>
        </section>
      </main>
      <footer>
        ${ButtonNavigation().render()}
      </footer>
    </div>
    `
  }
}

export default Donor
