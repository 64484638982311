import { renderComponent } from '../../lib'

import _AudioBar           from './AudioBar'
import _ButtonNavigation   from './ButtonNavigation'
import _MemberGroupModal   from './MemberGroupModal'
import _SearchModal        from './SearchModal'
import _Tile               from './Tile'

const AudioBar         = renderComponent(_AudioBar)
const ButtonNavigation = renderComponent(_ButtonNavigation)
const MemberGroupModal = renderComponent(_MemberGroupModal)
const SearchModal      = renderComponent(_SearchModal)
const Tile             = renderComponent(_Tile)

export { AudioBar }
export { ButtonNavigation }
export { MemberGroupModal }
export { SearchModal }
export { Tile }
