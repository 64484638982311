import getExtensionFromFileName from './getExtensionFromFileName'
import getMimeType    from './getMimeType'
import getPreloadType from './getPreloadType'

function preloadAsset(file, { isCrossOrigin = false }) {
  const extension = getExtensionFromFileName(file)
  const sourceType = getPreloadType(extension)
  const mimeType = getMimeType(extension)

  const asset = document.createElement('link')
  asset.rel = 'preload'
  asset.href = file

  if (sourceType) asset.as = sourceType
  if (mimeType) asset.type = mimeType
  if (isCrossOrigin) asset.setAttribute('crossorigin', '')

  document.head.insertAdjacentElement('beforeend', asset)
}

export default preloadAsset
