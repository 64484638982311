/* eslint-disable operator-linebreak */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
function parseVolume(volume) {
  // Accept input in the format of 0% - 100%
  if (typeof volume === 'string' && volume.includes('%')) {
    const number = parseFloat(volume, 10)

    if (Number.isNaN(number)) return NaN
    if (number <= 0) return 0
    if (number >= 100) return 1
    return number / 100
  }

  // Accept input as a number between 0 and 1
  if (typeof volume === 'string' || typeof volume === 'number') {
    const number = parseFloat(volume, 10)

    if (Number.isNaN(number)) return NaN
    if (number <= 0) return 0
    if (number >= 1) return 1
    return number
  }

  // No volume could be found
  return NaN
}

function parseTime(time, duration) {
  const number = parseFloat(time, 10)
  if (Number.isNaN(number) || !duration) return NaN

  if (number <= 0) return 0
  if (number >= duration) return duration
  return number
}

const sanitizeSettings = settings => {
  const config = {
    ...settings,
  }

  // set defaults
  // timeout <number: ms> how long the player should attempt to load before quitting
  // errorMode <boolean> when true, throw errors and log messages
  // startingVolume <number: 0-1 | string 0%-100%>
  if (!config.timeout) config.timeout = 3000
  if (!config.errorMode) config.errorMode = false
  if (!config.startingVolume) config.startingVolume = 1

  // handle required features
  if (!config.sources || !config.sources.length) {
    if (config.errorMode) throw new Error('Audio Player must have at least one source')
    return null
  }

  // settings are valid
  return config
}

class AudioPlayer {
  constructor(config) {
    this.config = sanitizeSettings(config)
    if (this.config === null) return

    this.state = {
      initialized: false,
      playing: false,
      reachedEnd: false,
      volumeHistory: [],
      customEvents: {
        onPlayerReady: new Event('playerready'),
        onPlayerEnd: new Event('playerend'),
      },
      synchronizedEvents: {
        // Audio events
        error: {},
        pause: {},
        play: {},
        playing: {},
        timeupdate: {},
        volumechange: {},

        // custom events
        playerready: {},
        playerend: {},
      },
    }

    this.audio = document.createElement('audio')

    const [firstAudio] = this.config.sources
    this.audio.src = firstAudio

    this.setVolume(this.config.startingVolume)

    this.initialize()
    this.sync('playerready', 'attachEvents', player => {
      // dispatch event when player has reached the end
      player.sync('timeupdate', 'trackPlayerEnd', self => {
        const { currentTime, duration } = self.audio
        const threshold = 0.5
        if (duration - currentTime <= threshold) {
          if (self.state.reachedEnd === false) {
            self.audio.dispatchEvent(self.state.customEvents.onPlayerEnd)
            self.state.reachedEnd = true
          }
        }
        else {
          self.state.reachedEnd = false
        }
      })
    })
  }

  log(message) {
    if (this.config.errorMode === true) console.log(message)
  }

  throw(message) {
    if (this.config.errorMode === true) throw new Error(message)
  }

  play() {
    // play is a promise, so set state once it is completed
    this.audio.play()
      .then(() => {
        this.state.playing = true
      })
  }

  pause() {
    this.audio.pause()
    this.state.playing = false
  }

  togglePlayPause() {
    const { playing } = this.state;
    (playing === true) ? this.pause() : this.play()
  }

  setTime(time) {
    const { duration } = this.audio
    const newTime = parseTime(time, duration)
    if (Number.isNaN(newTime)) return

    this.audio.currentTime = newTime
  }

  adjustTime(adjustment) {
    const { currentTime } = this.audio
    const number = Number(adjustment)
    if (Number.isNaN(number)) return

    this.setTime(currentTime + number)
  }

  reset() {
    this.pause()
    this.setTime(0)
  }

  setVolume(volume) {
    const newVolume = parseVolume(volume)
    if (Number.isNaN(newVolume)) return

    this.audio.volume = newVolume
    this.state.volumeHistory.push(this.audio.volume)
  }

  mute() {
    this.setVolume(0)
    this.state.volumeHistory.push(this.audio.volume)
  }

  unmute() {
    // set volume to the most recent volume that isn't muted
    const unmutedHistory = this.state.volumeHistory.filter(volume => volume > 0)
    this.setVolume(unmutedHistory[unmutedHistory.length - 1])
    this.state.volumeHistory.push(this.audio.volume)
  }

  toggleMute() {
    const { volume } = this.audio;
    (volume === 0) ? this.unmute() : this.mute()
  }

  sync(eventType, name, callback) {
    const { synchronizedEvents } = this.state
    // validate arguments
    if (
      !eventType || typeof eventType !== 'string' ||
      !name || typeof name !== 'string' ||
      !callback || typeof callback !== 'function') {
      this.throw('eventType, name, and callback are required parameters at AudioPlayer.sync')
      return
    }

    if (synchronizedEvents[eventType] === undefined) {
      this.throw('eventType must correspond with an event at AudioPlayer.sync')
      return
    }

    if (synchronizedEvents[eventType][name] !== undefined) {
      this.throw('name already exists at the designated eventType at AudioPlayer.sync')
      return
    }

    synchronizedEvents[eventType][name] = callback
  }

  unsync(eventType, name) {
    const { synchronizedEvents } = this.state
    // validate arguments
    if (
      !eventType || typeof eventType !== 'string' ||
      !name || typeof name !== 'string'
    ) {
      this.throw('eventType and name are required parameters at AudioPlayer.unsync')
      return
    }

    if (synchronizedEvents[eventType] === undefined) {
      this.throw('eventType must correspond with an event at AudioPlayer.unsync')
      return
    }

    if (synchronizedEvents[eventType][name] === undefined) {
      this.throw('nothing to unsync at AudioPlayer.unsync')
      return
    }

    delete synchronizedEvents[eventType][name]
  }

  initialize() {
    if (this.state.initialized) {
      this.throw('Audio Player is already initialized')
      return
    }

    const errorState = () => {
      throw new Error('init->errorState:: An Error Occured')
    }

    const successState = () => {
      const { synchronizedEvents } = this.state
      // Build Event Listeners
      // events are assigned to this.synchronizedEvents by this.sync()
      // each time these events are fired, it pulls all registered events
      // and self-updates
      const events = Object.keys(synchronizedEvents)

      const buildListeners = eventName => {
        this.audio.addEventListener(eventName, event => {
          const functions = synchronizedEvents[eventName]
          Object.keys(functions).forEach(name => functions[name](this, event))
        })
      }
      events.forEach(eventName => buildListeners(eventName))

      // Dispatch the 'playerready' event
      this.audio.dispatchEvent(this.state.customEvents.onPlayerReady)
    }

    // if player has loaded, make it interactive
    if (this.audio.readyState >= 3) {
      this.log('init()::readyState::player did not have to wait')
      successState()

      // wait for player to load, then make it interactive
    }
    else {
      this.audio.addEventListener('canplay', () => {
        this.log('initialize->canplay<event>:: Player waited to load')
        successState()
      }, { once: true })
    }

    this.audio.addEventListener('error', () => {
      this.log('initialize->error<event>:: Player encountered an error')
      errorState()
    })

    if (this.config.timeout !== -1) {
      setTimeout(() => {
        if (this.audio.readyState < 3) {
          this.log('initialize->setTimeout:: Player timed out')
          errorState()
        }
      }, this.config.timeout)
    }
  }
}

export default AudioPlayer
