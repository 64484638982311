// Query Each
// accepts a string selector and a callback function
// performs the callback on each element selected

const queryEach = (selector, callback, source = document) => {
  if (!(source instanceof Node)) {
    throw new TypeError(`source must be a Node. Instead got ${typeof source}: "${source}"`)
  }
  const nodes = source.querySelectorAll(selector)
  if (!nodes.length) return
  nodes.forEach(callback)
}

export default queryEach
