// History entries are managed for back-button functionality
// Each time a page is re-rendered, a new history state is pushed

// There are a few cases we need to account for, to keep this
// working smoothly:
// 1: Whenever back button is pressed, it should remove the last entry
// 2: Sometimes the same page is rendered multiple times, such as when
//    asynchronous functions are completed or a donor is a search result
//    on their own donor page. Pressing the back button should take
//    the user to the last different page
// 3: History fires every time something is rendered. If the board is
//    left up for a long time, this history array could get infinitely
//    huge. Lets reset our history state each time we visit the homepage

// we can handle cases 2 and 3 with cleanHistoryEntries
import { removeConsecutiveDuplicates } from './helpers'

function cleanHistoryEntries(_history) {
  let history = removeConsecutiveDuplicates(_history)

  // reset history to last homepage visit
  const homeIndex = history.lastIndexOf('/')
  if (homeIndex !== -1) {
    history = history.slice(homeIndex)
  }

  return history
}

export default cleanHistoryEntries
