const icons = {
  back: ({ id, className } = {}) => `
  <svg
    ${id ? `id="${id}"` : ''}
    ${className ? `class="${className}"` : ''}
    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67 48">
    <path d="M66.85 48.28l-1.84-1.71c-20.85-19.31-38.4-14.5-41.19-13.56v14.61L0 23.81 23.82 0v14c4.55.08 31.72 1.82 42.2 31.91zM1.77 23.81l20.8 20.8V32.16l.38-.16c.19-.08 18.62-7.47 41 12-11.71-29.47-40.44-28.76-40.73-28.75h-.65V3.01z"/>
  </svg>
  `,

  home: ({ id, className } = {}) => `
  <svg
    ${id ? `id="${id}"` : ''}
    ${className ? `class="${className}"` : ''}
    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 67">
    <path d="M69.59 34.63l-8.91-8.7V9.62H49.15v5l-15-14.66L.28 34.64a1 1 0 00-.05 1.33h8.93v31.52h19.17V44.76h13.22v22.73h19.17V35.97h8.92a1 1 0 00-.05-1.34zm-10.12.09v31.52H42.8V43.51H27.08v22.73H10.41V34.72H3L34.18 2.83 50.4 18.67v-7.8h9V27.5l7.39 7.22z"/>
  </svg>
  `,

  search: ({ id, className } = {}) => `
  <svg
    ${id ? `id="${id}"` : ''}
    ${className ? `class="${className}"` : ''}
    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
    <path d="M67.3 65.8l-18-18c-.5-.5-1.3-.7-2-.4L45 45.2c4.7-4.4 7.8-10.6 7.8-17.6 0-13.2-10.8-24-24-24s-24 10.8-24 24 10.8 24 24 24c5.7 0 11-2 15.1-5.4l2.3 2.3c-.3.7-.1 1.5.4 2l18 18c.4.4.9.5 1.4.5s1-.2 1.3-.5c.7-.7.7-1.9 0-2.7zM6.6 27.6c0-12.3 9.9-22.2 22.2-22.2C41.1 5.4 51 15.3 51 27.6c0 12.3-9.9 22.2-22.2 22.2-12.3 0-22.2-9.9-22.2-22.2z"/>
    <path d="M15 42.3c.9.6 1.8 1.5 2.7 2.1v-1.5c-.9-.6-1.8-1.5-2.7-2.1v1.5zM8.1 27.6c0 5.1 2.1 9.6 5.1 12.9v-2.1c-2.4-3-3.9-6.9-3.9-10.8 0-6.3 3.3-12 8.4-15v-1.5c-5.7 3.3-9.6 9.3-9.6 16.5z"/>
  </svg>
  `,

  'volume-up': ({ id, className } = {}) => `
  <svg
    ${id ? `id="${id}"` : ''}
    ${className ? `class="${className}"` : ''}
    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
    <path d="M36.1 69.7c-18.5 0-33.6-15.1-33.6-33.6S17.6 2.5 36.1 2.5s33.6 15.1 33.6 33.6-15.1 33.6-33.6 33.6zm0-66.2a32.6 32.6 0 10.01 65.21A32.6 32.6 0 0036.1 3.5z"/>
    <path d="M43.8 51.5c-.2 0-.5-.1-.6-.4-.1-.3 0-.7.3-.8 5.4-2.3 8.9-7.6 8.9-13.5S49 25.6 43.6 23.3c-.3-.1-.5-.5-.3-.8.1-.3.5-.5.8-.3 5.9 2.5 9.6 8.3 9.6 14.6 0 6.4-3.8 12.1-9.6 14.6-.1 0-.2.1-.3.1z"/>
    <path d="M40.4 47.1c-.2 0-.4-.1-.5-.3-.2-.3-.1-.7.2-.9a10.7 10.7 0 000-18.2.65.65 0 11.7-1.1c3.6 2.2 5.7 6 5.7 10.2s-2.1 8-5.7 10.2c-.2.1-.3.1-.4.1zM36 54.3l-10-10H15.5v-15H26l10-10v35zM16.8 43h9.8l8.3 8.3v-29l-8.3 8.3h-9.8V43z"/>
  </svg>
  `,

  'play-mini': ({ id, className } = {}) => `
  <svg
    ${id ? `id="${id}"` : ''}
    ${className ? `class="${className}"` : ''}
    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M4.9 1v22l17.3-11L4.9 1z"></path>
  </svg>
  `,

  'pause-mini': ({ id, className } = {}) => `
  <svg
    ${id ? `id="${id}"` : ''}
    ${className ? `class="${className}"` : ''}
    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"/>
  </svg>
  `,

  'refresh-mini': ({ id, className } = {}) => `
  <svg
    ${id ? `id="${id}"` : ''}
    ${className ? `class="${className}"` : ''}
    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M12 6v3l4-4-4-4v3a8 8 0 00-6.76 12.26L6.7 14.8A6 6 0 0112 6zm6.76 1.74L17.3 9.2A6 6 0 0112 18v-3l-4 4 4 4v-3a8 8 0 006.76-12.26z"/>
  </svg>
  `,

  'rewind-fifteen-mini': ({ id, className } = {}) => `
  <svg
    ${id ? `id="${id}"` : ''}
    ${className ? `class="${className}"` : ''}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 20">
    <path d="M11 13.75c0 .75-.54 1.39-1.74 1.39a2.84 2.84 0 01-1.6-.45L8 14a2 2 0 001.19.38c.49 0 .79-.21.79-.55s-.22-.54-1-.54H7.94l.21-2.37h2.56v.78H9l-.06.8h.34c1.2-.05 1.72.5 1.72 1.25zM6.65 10.87v4.2h-1v-3.42h-.81v-.78z"></path>
    <path d="M0 12a8 8 0 108-8V0L3 5l5 5V6a6 6 0 11-6 6z"></path>
  </svg>
  `,

  'advance-fifteen-mini': ({ id, className } = {}) => `
  <svg
    ${id ? `id="${id}"` : ''}
    ${className ? `class="${className}"` : ''}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 20">
    <path d="M11 13.75c0 .75-.54 1.39-1.74 1.39a2.84 2.84 0 01-1.6-.45L8 14a2 2 0 001.19.38c.49 0 .79-.21.79-.55s-.22-.54-1-.54H7.94l.21-2.37h2.56v.78H9l-.06.8h.34c1.2-.05 1.72.5 1.72 1.25zM6.65 10.87v4.2h-1v-3.42h-.81v-.78z"></path>
    <path d="M14 12a6 6 0 11-6-6v4l5-5-5-5v4a8 8 0 108 8z"></path>
  </svg>
  `,

  'angle-bracket-right-small': ({ id, className } = {}) => `
  <svg
    ${id ? `id="${id}"` : ''}
    ${className ? `class="${className}"` : ''}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36 67">
      <path d="M2.65 66.85L0 64.19l30.77-30.77L0 2.65 2.65 0l33.43 33.42L2.65 66.85z"/>
    </svg>
    `,

  'angle-bracket-right-medium': ({ id, className } = {}) => `
  <svg
    ${id ? `id="${id}"` : ''}
    ${className ? `class="${className}"` : ''}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 35 66">
      <path d="M1.77 65.96L0 64.19l31.21-31.21L0 1.77 1.77 0l32.98 32.98L1.77 65.96z"/>
    </svg>
    `,

  'angle-bracket-right': ({ id, className } = {}) => `
  <svg
    ${id ? `id="${id}"` : ''}
    ${className ? `class="${className}"` : ''}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 33 65">
      <path d="M.88 65.08L0 64.19l31.66-31.65L0 .88.88 0l32.54 32.54L.88 65.08z"/>
    </svg>
    `,
}

export default icons
