import { removeClass } from './helpers'

function configureSearchButton() {
  const searchButtons = document.querySelectorAll('[data-search-button]')
  const modalOverlay = document.querySelector('#search-modal')
  if (!searchButtons || !modalOverlay) return

  searchButtons.forEach(button => {
    button.addEventListener('click', () => {
      // dirty hack to ensure this gets fired after any subsequent clicks
      setTimeout(() => {
        removeClass('.overlay', 'is-active')
        modalOverlay.classList.add('is-active')
      }, 0)
    })
  })
}

export default configureSearchButton
