/* eslint-disable no-use-before-define */
import Keyboard from 'simple-keyboard'
import { isLastCharacterCapitalized } from './helpers'

function configureSearchKeyboard() {
  const keyboardElement = document.querySelector('.simple-keyboard')
  const inputElement = document.querySelector('.search_input')

  if (!inputElement || !keyboardElement) return

  const keyboard = new Keyboard({
    onChange: input => onChange(input),
    onKeyPress: button => onKeyPress(button),
    layout: {
      default: [
        '~ q w e r t y u i o p {bksp}',
        '~ a s d f g h j k l ~ ~',
        '{shift} z x c v b n m ~ ~ {shift}',
        '~ ~ {space} ~ ~',
      ],
      shift: [
        '~ Q W E R T Y U I O P {bksp}',
        '~ A S D F G H J K L ~ ~',
        '{shift} Z X C V B N M ~ ~ {shift}',
        '~ ~ {space} ~ ~',
      ],
    },
    preventMouseDownDefault: true, // if input is focused, retain focus
  })

  function handleShift() {
    const currentLayout = keyboard.options.layoutName
    const shiftToggle = (currentLayout === 'default') ? 'shift' : 'default'

    keyboard.setOptions({
      layoutName: shiftToggle,
    })
  }

  function onKeyPress(button) {
    if (button === '{shift}' || button === '{lock}') handleShift(keyboard)
  }

  function onChange(input) {
    // eslint-disable-next-line no-param-reassign
    if (input.includes('~')) input = input.replace(/~/g, '')
    inputElement.value = input

    const wasCapitalized = isLastCharacterCapitalized(inputElement.value)
    const { layoutName } = keyboard.options

    if (wasCapitalized && layoutName === 'shift') {
      keyboard.setOptions({
        layoutName: 'default',
      })
    }
    // the 'input' event does not trigger for touch
    // so let's create an event we can tap into
    inputElement.dispatchEvent(new CustomEvent('update-input', { detail: { input } }))
  }

  // Ensure input element is focused while keyboard is in use
  keyboardElement.addEventListener('click', () => inputElement.focus())
}

export default configureSearchKeyboard
