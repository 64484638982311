import { ButtonNavigation, SearchModal, MemberGroupModal } from '../components'
import { formatCharacters, by } from '../../lib'

class Group {
  constructor(state, props) {
    const [group] = props
    this.state = state
    this.group = group || {}
  }

  renderStyles() {
    const { photo } = this.group

    return `
      <style>
        #group::before { background-image: url(${photo}) }
      </style>
    `
  }

  renderMembers() {
    const members = [...this.group.members]

    return members
      .sort(by('name', 'index'))
      .reduce((HTML, member) => {
        const { name, index, thumbnail } = member
        return HTML + `
        <li>
          <a href="/donor/${index}" data-navigo>
          <img src="${thumbnail}">
          <div>${formatCharacters(name, { className: 'muted' })}</div>
          </a>
        </li>
      `
      }, '')
  }

  render() {
    const { name, description, photo, plural } = this.group

    return `
    ${photo ? this.renderStyles() : ''}
    ${SearchModal().render()}
    ${MemberGroupModal().render()}
    <div id="group" class="basic-page">
      <main>
        <section class="group-info">
          <h3 class="group-title">${name}</h3>
          <div class="group-description">${description || ''}</div>
        </section>
        <section class="members-section">
          <h3>${plural}</h3>
          <ul class="member-list">
            ${this.renderMembers()}
          </ul>
        </section>
      </main>
      <footer>
        ${ButtonNavigation().render()}
      </footer>
    </div>
  `
  }
}

export default Group
